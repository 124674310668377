import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { set } from "mongoose";

const QuizComponent = ({
  currentQuestion,
  currentQuestionIndex,
  storeAnswer,
  handleStoreAnswer,
  ///
  justifyText,
  setJustifyText,
  //
  withoutJustificationIndexes,
}) => {
  const currentSectionIndex = useSelector((state) => state.authReducer.currentSectionIndex);
  const imageURLS = JSON.parse(localStorage.getItem("imageURLS"));
  const question_id = currentQuestion?.question_id;
  const [isImageAvailable, setIsImageAvailable] = useState(false);

  useEffect(() => {
    setIsImageAvailable(false);
  
    if (imageURLS && imageURLS[currentSectionIndex] && imageURLS[currentSectionIndex].questions) {
      imageURLS[currentSectionIndex].questions.forEach((question) => {
        if (question.question_id === question_id) {
          if (question.question_image_url) {
            setIsImageAvailable(true);
          } else if (question.options && question.options.some(option => option.option_image_url)) {
            setIsImageAvailable(true);
          }
        }
      });
    }
  }, [currentQuestion, currentSectionIndex, question_id, imageURLS]);
  return (
    <div className="col-9 left_section_question_container">
      <div
        id="question-container-scroll"
        className=" mb-4 px-4 ps ps--active-y ps--scrolling-y"
      >
        <div id="questionContainer">
          <div className="col-12 card mb-4 p-4">
            <label className="form-label ques_title" htmlFor="Question_1">
              Question: <strong>{currentQuestionIndex + 1 || 0}</strong>
            </label>
            {currentQuestion && (
              <div className="ques_text">
                {currentQuestion.question_text.split('\n\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < currentQuestion.question_text.split('\n\n').length - 1 && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
            {isImageAvailable && (() => {
              const questionImageUrl = imageURLS[currentSectionIndex]?.questions?.find(
                (question) => question.question_id === question_id
              )?.question_image_url;
            
              return questionImageUrl ? (
                <div className="ques_img">
                  <img
                    src={questionImageUrl}
                    alt="question"
                    style={{ width: "40%" }}
                  />
                </div>
              ) : null;
            })()}
          </div>
          {/* <br /> */}
          {!withoutJustificationIndexes.includes(
            currentQuestion?.question_id || 0
          ) ? (
          <h5>
            Select the option and justify your answer below this
            section (Required)
          </h5>) : (
            <h5>
              Select the option (Required)
            </h5>
          )}

          <div
            className="options d-flex flex-wrap row"
            style={{ justifyContent: "space-between" }}
          >
            {currentQuestion?.options?.map((answer, _idx) => {
              const { option, text } = answer;
              return (
                <div
                  className="col-6 my-2 questions_item"
                  key={`${option}-${_idx}`}
                >
                  <div
                    className="card p-3 align-items-left align-items-md-start cursor-pointer option-card"
                    style={{
                      border:
                        storeAnswer?.text === text
                          ? "2px solid #5e16da"
                          : "2px solid transparent",
                    }}
                    onClick={() => handleStoreAnswer({ text, currentQuestion })}
                  >
                    <label
                      className="form-check-label m-1 ques_ans_wrapper"
                      htmlFor={text}
                    >
                      <span
                        className="form-check-input m-1 idx_box"
                        style={{
                          background:
                            storeAnswer?.answer === text ? "#5e16da" : "",
                          color: storeAnswer?.answer === text ? "#fff" : "",
                          border:
                            storeAnswer?.answer === text
                              ? "1px solid #5e16da"
                              : "1px solid lightgray",
                        }}
                        // onClick={() =>
                        //   handleStoreAnswer({ answer, currentQuestion })
                        // }
                      >
                        {_idx + 1}
                      </span>
                      <p className="idx_answer">{text}</p>
                    </label>
                    {isImageAvailable && (() => {
                      const optionImageUrl = imageURLS[currentSectionIndex]?.questions?.find(
                        (question) => question.question_id === question_id
                      )?.options[_idx]?.option_image_url;
                    
                      return optionImageUrl ? (
                        <img
                          src={optionImageUrl}
                          alt="option"
                          style={{ width: "25%" }}
                        />
                      ) : null;
                    })()}
                  </div>
                </div>
              );
            })}
          </div>

          {/* justification */}
          {!withoutJustificationIndexes.includes(
            currentQuestion?.question_id || 0
          ) ? (
            <div className="mb-4 pt-4 ps">
              <h5 style={{ fontWeight: "bold" }}>
                Please justify your answer (Required)
              </h5>
              <textarea
                id="justification_1"
                name="justificationInput"
                className="form-control"
                placeholder="Write your justification here"
                style={{ height: "170px" }}
                disabled={!storeAnswer}
                //
                value={justifyText}
                onChange={(e) => setJustifyText(e.target.value)}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default QuizComponent;
