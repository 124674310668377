import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import screenfull from "screenfull";
import AssesmentIntro from "../../components/AssesmentComponents/AssesmentIntro";
import AssesmentWrapper from "../../components/AssesmentComponents/AssesmentWrapper";
import { setTime, decrementTime } from "../../slices/timerSlice";

const Assesment = () => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const remainingTime = useSelector(
    (state) => state.timerReducer.remainingTime
  );

  const currentQuestion = useSelector((state) => state.authReducer.currentQuestion);

  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showIntro, setShowIntro] = useState(
    () => localStorage.getItem("showIntro") !== "false"
  );

  const toggleFullScreen = () => {
    setIsWarningVisible(false);
    if (screenfull.isEnabled) {
      screenfull.toggle(modalRef.current);
      setShowIntro(false);
      localStorage.setItem("showIntro", "false");
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(screenfull.isFullscreen);
      if (!screenfull.isFullscreen) {
        setIsWarningVisible(true);
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsWarningVisible(true);
      }
    };

    const handleKeyDown = (event) => {
      const charCode = event.charCode || event.keyCode || event.which;
      if (charCode === 27 && screenfull.isFullscreen) {
        event.preventDefault();
        event.stopImmediatePropagation();
        screenfull.exit();
      } else if (charCode === 18 && screenfull.isFullscreen) {
        // Alt key
        event.preventDefault();
        event.stopImmediatePropagation();
        screenfull.exit();
      }
    };

    if (screenfull.isEnabled) {
      screenfull.on("change", handleFullscreenChange);
      document.addEventListener("keydown", handleKeyDown, true);
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", handleFullscreenChange);
      }
      document.removeEventListener("keydown", handleKeyDown, true);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Only reload the page if certain critical data changes
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "userDetails" || event.key === "showIntro") {
        window.location.reload();
      } else if (event.key === "remainingTime") {
        dispatch(setTime(parseInt(event.newValue, 10)));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);

  // Start and manage the timer
  useEffect(() => {
    if (remainingTime === 0) return;

    const showIntroExists = localStorage.getItem("showIntro") !== null;
    if (showIntroExists) {
      const intervalId = setInterval(() => {
        dispatch(decrementTime());
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [dispatch, remainingTime]);

  return (
    <div>
      {showIntro && <AssesmentIntro toggleFullScreen={toggleFullScreen} />}
      <div ref={modalRef} className="test">
        {isFullScreen ? (
          <div className="content" style={{ background: "#fff" }}>
            <AssesmentWrapper currentQuestion={currentQuestion} />
          </div>
        ) : (
          !showIntro && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "10px",
              }}
            >
              <p>
                We have found some vulnerable activities. Please return to
                full-screen mode to continue the test.
              </p>
              <button
                onClick={toggleFullScreen}
                className="btn me-sm-2 waves-effect waves-light"
                style={{
                  border: "1px solid #5e16da",
                  background: "white",
                  color: "#5e16da",
                }}
              >
                Re-enter Fullscreen
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Assesment;
